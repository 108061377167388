import React, { useEffect, useState } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const CustomPagination: React.FC<PaginationProps> = ({ page, totalPages, onPageChange }) => {
  const [pagination, setPagination] = useState<(number | string)[]>([]);
  const [presentPage, setPresentPage] = useState<number>(1);
  const ellipseStyle = {
    pointerEvents: 'none',
    color: 'black',
    background: 'none',
    outline: 'none',
  };
  const currentPageStyle = { color: '#5c85ff', background: '#ebf0ff' };
  const otherPageStyle = { color: '#474d66', background: 'none', outline: 'none' };

  useEffect(() => {
    if (totalPages <= 7) {
      setPagination(Array.from({ length: totalPages }, (_, i) => i + 1));
      return;
    }
    const pagination: (number | string)[] = [1];
    // 1 is fixed member of pagination array
    if (presentPage <= 3) {
      pagination.push(2, 3, 4, 5, '...', totalPages);
    } else if (presentPage >= totalPages - 2) {
      pagination.push(
        '...',
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      );
    } else {
      pagination.push('...', presentPage - 1, presentPage, presentPage + 1, '...', totalPages);
    }
    setPagination(pagination);
  }, [presentPage, totalPages]);

  const handlePage = async (currentPage: number | string) => {
    if (currentPage !== page && typeof currentPage === 'number') {
      onPageChange(currentPage);
      setPresentPage(currentPage);
    }
  };

  return (
    <HStack spacing={1} display="flex" alignItems={'center'} justifyContent={'center'}>
      <Button
        onClick={() => handlePage(page - 1)}
        style={{ outline: 'none', background: 'none' }}
        isDisabled={page === 1}
        size={'sm'}
      >
        <MdKeyboardArrowLeft />
      </Button>
      {pagination?.map((currentPage: number | string, index: number) => (
        <Button
          key={index}
          style={{
            fontWeight: 600,
            fontSize: '12px',
            ...(currentPage === '...'
              ? ellipseStyle
              : currentPage === page
              ? currentPageStyle
              : otherPageStyle),
          }}
          onClick={() => handlePage(currentPage)}
          size={'sm'}
        >
          {currentPage}
        </Button>
      ))}
      <Button
        onClick={() => handlePage(page + 1)}
        isDisabled={page === totalPages}
        style={{ outline: 'none', background: 'none' }}
        size={'sm'}
      >
        <MdKeyboardArrowRight />
      </Button>
    </HStack>
  );
};

export default CustomPagination;
