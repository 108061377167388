import { AuthState } from '@aws-amplify/ui-components';
import {
  Box,
  Container,
  Flex,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Button,
  Text,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { routerActions } from 'connected-react-router';
import React, { memo, useEffect, useState } from 'react';
import {
  FiBell,
  FiCalendar,
  FiMail,
  FiThumbsUp,
  FiFileText,
  FiCreditCard,
  FiLogOut,
} from 'react-icons/fi';
import { TiArrowSortedDown } from 'react-icons/ti';
import { useAppDispatch, useAppSelector } from '../hooks';
import { signOutAsync } from '../slice/userSlice';
import './shell.scss';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import logo01 from '../assets/images/logo01.png';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex mt={30} flexDir="column" w="100%">
      <Menu placement="right">
        <Link
          backgroundColor={active ? '#cbe7ff' : 'inherit'}
          p={3}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#e8f4ff' }}
          w={'auto'}
          onClick={onClick}
        >
          <Flex>
            <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} />
            <Text ml={5} display={'flex'}>
              {title}
            </Text>
          </Flex>
        </Link>
      </Menu>
    </Flex>
  );
};

const navs: any = [
  { icon: FiBell, title: 'お知らせ', active: false, pathname: '/notices' },
  { icon: FiCalendar, title: 'レッスン', active: false, pathname: '/lessons' },
  { icon: FiCreditCard, title: 'レッスン料', active: false, pathname: '/fees' },
  { icon: FiFileText, title: '申し送り', active: false, pathname: '/lesson_notes' },
  { icon: FiThumbsUp, title: 'フィードバック', active: false, pathname: '/feedback' },
  { icon: FiMail, title: 'お問い合わせ', active: false, pathname: '/contact' },
];
export interface IShellProps {
  children: React.ReactNode;
}

export var orgKey = React.createContext('kokoka');
export var currentUserId = React.createContext('');

const getAuthenticateInfo = async (attributeName: string = 'currentUserId') => {
  const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
  if (authenticatedUser) {
    const currentUserId = await authenticatedUser.attributes['sub'];
    return currentUserId;
  }
  return '';
};

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector((state) => state.router);
  const userState = useAppSelector((state) => state.user);
  const [companyId, setCompanyId] = useState<any>('くらしスタディ はなすプラス');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const onClickProfile = (close: () => void) => {
    // TODO
    close();
  };
  const { pathname } = useLocation();

  const onClickSignout = async () => {
    await dispatch(signOutAsync());
  };

  const onBlur = (close: () => void) => {
    // TODO
    close();
  };

  useEffect(() => {
    getAuthenticateInfo().then((teacherId) => {
      currentUserId = React.createContext(teacherId);
    });
    return;
  });

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex={500}
        bg="white"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Box className="Logo" onClick={onClickBrand} style={{ cursor: 'pointer' }}>
            <img src={logo01} alt={companyId} />
          </Box>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            | レッスン管理システム
          </sub>
        </Box>
        <Box hidden={!(userState.authState === AuthState.SignedIn && userState.user)}>
          <Flex marginRight={'10px'}>
            <Menu>
              <MenuButton
                as={Button}
                fontSize="12px"
                fontWeight={500}
                marginRight={3}
                height={'32px'}
                background={'white'}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="4px"
                padding="1px 12px"
                rightIcon={<TiArrowSortedDown size={13} />}
                _hover={{ background: 'white', borderColor: 'gray.500' }}
                _focus={{ background: 'white', boxShadow: 'none' }}
                _active={{ background: 'white' }}
              >
                {userState.user?.email}
              </MenuButton>
              <MenuList>
                <MenuItem
                  as={Button}
                  fontSize="14px"
                  fontWeight={400}
                  leftIcon={<Icon as={FiLogOut} boxSize="22px" color="red.500" fontWeight="bold" />}
                  background={'white'}
                  onClick={onClickSignout}
                  color="red.500"
                  justifyContent="flex-start"
                  ml={'7px'}
                  _hover={{ background: 'transparent', color: 'red.500' }}
                  _focus={{ background: 'transparent', boxShadow: 'none' }}
                  _active={{ background: 'transparent' }}
                >
                  Signout
                </MenuItem>
              </MenuList>
            </Menu>
            <Box>
              <a href="#">
                <div
                  style={{
                    border: 'none',
                    background: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    outline: 'none',
                  }}
                >
                  <QuestionOutlineIcon boxSize={7} color="#3182ce" />
                </div>
              </a>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Flex>
        <Flex
          pos="sticky"
          borderRight="1px solid #ddd"
          boxShadow="sm"
          minW="200px"
          flexDir="column"
          justifyContent="space-between"
          top="0"
          h="100vh"
          overflowY="auto"
          hidden={!(userState.authState === AuthState.SignedIn && userState.user)}
        >
          <Flex className="scrollable-sidebar" flexDir="column" marginTop={'60px'} as="nav">
            <>
              {navs?.map((nav: any) => (
                <NavItem
                  key={nav.title}
                  icon={nav.icon}
                  title={nav.title}
                  active={nav.pathname === historyState.location.pathname}
                  onClick={() => dispatch(routerActions.push(nav.pathname))}
                />
              ))}
            </>
          </Flex>
        </Flex>
        <Container
          className="Shell-children main-content"
          marginTop={'55px'}
          maxWidth={'100vw'}
          marginX={'2em'}
        >
          <>{children}</>
        </Container>
      </Flex>
    </>
  );
});
